import { createSSRApp, createApp as createClientApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import { createServerHead, createHead } from '@unhead/vue'
import { createRouter } from './router'
import { i18nConfig } from './utils/i18n-config'
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query'

import App from './app.vue'

// SSR requires a fresh app instance per request, therefore we export a function
// that creates a fresh app instance. If using Vuex, we'd also be creating a
// fresh store here.
export function createApp() {
  const app = import.meta.env.SSR ? createSSRApp(App) : createClientApp(App)

  app.config.performance = import.meta.env.DEV

  const i18n = createI18n(i18nConfig)
  const store = createPinia().use(({ store }) => {
    store.i18n = i18n
    store.router = router
  })
  const router = createRouter()
  const head = import.meta.env.SSR ? createServerHead() : createHead()
  const queryClient = new QueryClient()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  app
    .use(i18n)
    .use(store)
    .use(router)
    .use(head)
    .use(VueQueryPlugin, { queryClient })

  return { app, router, i18n, store, queryClient, head }
}
