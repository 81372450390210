import { defineStore } from 'pinia'

export type RoleList = 'member'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      isLoggedIn: false,
      roles: new Set<RoleList>([]),
    }
  },
  actions: {
    setAuth(value: boolean) {
      this.isLoggedIn = value
      this.roles = new Set([])
    },
  },
})
