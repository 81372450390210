<script setup lang="ts">
import Header from '@/components/Header.vue'
import { useHead } from 'unhead'
import { useI18n } from 'vue-i18n'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import '@/assets/root.css'

const i18n = useI18n({ useScope: 'global' })

useHead({
  title: 'Title',
  htmlAttrs: {
    lang: i18n.locale.value,
  },
  meta: [
    { name: 'description', content: 'Description' },
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
    },
    { name: 'HandheldFriendly', content: 'true' },
  ],
})

// const isSSR = import.meta.env.SSR
</script>

<template>
  <Header />
  <router-view />
  <VueQueryDevtools button-position="bottom-right" />
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
