import { createQueryFunction } from './api'
import { createApp } from './main'
import { initSentry } from './utils/sentry'
import { type QueryFunction, hydrate } from '@tanstack/vue-query'

const { app, router, i18n, store, queryClient } = createApp()

initSentry(app, router)

const languagesBrowser = navigator.languages.join(',')
const language = i18n.global.availableLocales.find((lang) =>
  languagesBrowser.includes(lang)
)
i18n.global.locale = window.__LANGUAGE__ || language || 'ru'
console.log('[I18n] Language:', i18n.global.locale)

queryClient.setDefaultOptions({
  queries: {
    gcTime: 1000 * 30, // 30 seconds
    staleTime: 60_000, // sec
    queryFn: createQueryFunction({
      'Accept-Language': i18n.global.locale,
    }) as QueryFunction,
  },
})

hydrate(queryClient, window.__QUERY_CLIENT__ || {})
console.log('[QueryClient] Inited with state:', window.__QUERY_CLIENT__)
store.state.value = window.__INITIAL_STATE__ || {}
console.log('[Store] Inited with state:', window.__INITIAL_STATE__)

router.onError((error) => {
  console.error('[Router] Error:', error)
})
// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  console.log('[Router] Ready')
  app.mount('#app', Boolean(window.__INITIAL_STATE__))
})

setTimeout(() => {
  for (const href of window.__ALL_RESOURCES__ || []) {
    const preloadLink = document.createElement('link')
    preloadLink.href = href
    preloadLink.rel = 'preload'
    preloadLink.fetchPriority = 'low'
    switch (true) {
      case href.endsWith('.js'):
        preloadLink.rel = 'modulepreload'
        preloadLink.as = 'script'
        preloadLink.crossOrigin = ''
        break
      case href.endsWith('.css'):
        preloadLink.rel = 'stylesheet'
        preloadLink.as = 'style'
        break
      case href.endsWith('.ttf'):
        preloadLink.as = 'font'
        preloadLink.type = 'font/ttf'
        preloadLink.crossOrigin = ''
        break
      case href.endsWith('.woff'):
        preloadLink.as = 'font'
        preloadLink.type = 'font/woff'
        preloadLink.crossOrigin = ''
        break
      case href.endsWith('.woff2'):
        preloadLink.as = 'font'
        preloadLink.type = 'font/woff2'
        preloadLink.crossOrigin = ''
        break
      case href.endsWith('.gif'):
        preloadLink.as = 'image'
        preloadLink.type = 'image/gif'
        break
      case href.endsWith('.avif'):
        preloadLink.as = 'image'
        preloadLink.type = 'image/avif'
        break
      case href.endsWith('.svg'):
        preloadLink.as = 'image'
        preloadLink.type = 'image/svg+xml'
        break
      case href.endsWith('.jpg') || href.endsWith('.jpeg'):
        preloadLink.as = 'image'
        preloadLink.type = 'image/jpeg'
        break
      case href.endsWith('.png'):
        preloadLink.as = 'image'
        preloadLink.type = 'image/png'
        break
      case href.endsWith('.webp'):
        preloadLink.as = 'image'
        preloadLink.type = 'image/webp'
        break
    }

    document.head.appendChild(preloadLink)
  }
}, 32_000)
