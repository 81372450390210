<script lang="ts">
export default {
  name: 'LoginPage',
  inheritAttrs: false,
  customOptions: {},
}
</script>

<template>
  <h1 :class="$style.label">
    LoginPage
  </h1>
</template>

<style module>
.label {
  margin: 0 1em;
  font-weight: bold;
}
</style>
