import { useAuthStore, type RoleList } from '@/store/auth'
import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory,
  type Router,
  type RouteRecordRaw,
} from 'vue-router'

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    roles?: RoleList[]
  }
}

export enum RouteName {
  home = 'home',
  about = 'about',
  search = 'search',
  login = 'login',
  profile = 'profile',
  PermissionDenied = 'permission-denied',
  NotFound = 'not-found',
}

const HomePage = () => import('@/pages/Home.vue')
const AboutPage = () => import('@/pages/About.vue')
const SearchPage = () => import('@/pages/Search.vue')
const LoginPage = () => import('@/pages/Login.vue')
const ProfilePage = () => import('@/pages/Profile.vue')
const PermissionDeniedPage = () => import('@/pages/PermissionDenied.vue')
const NotFoundPage = () => import('@/pages/NotFound.vue')

const routes: RouteRecordRaw[] = [
  { name: RouteName.home, path: '/', component: HomePage },
  { name: RouteName.about, path: '/about', component: AboutPage },
  { name: RouteName.search, path: '/search', component: SearchPage },
  { name: RouteName.login, path: '/login', component: LoginPage },
  {
    name: RouteName.PermissionDenied,
    path: '/permission-denied',
    component: PermissionDeniedPage,
  },
  {
    name: RouteName.profile,
    path: '/profile',
    component: ProfilePage,
    meta: {
      requiresAuth: true,
      roles: ['member'],
    },
  },
  {
    name: RouteName.NotFound,
    path: '/:pathMatch(.*)*',
    component: NotFoundPage,
  },
] as const

export function createRouter(): Router {
  const router = _createRouter({
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes,
  })

  // Route guard
  router.beforeEach((to) => {
    try {
      const store = useAuthStore()
      if (to.meta.requiresAuth && !store.isLoggedIn) {
        return { name: RouteName.login }
      }
      if (to.meta.roles?.some((role) => role && !store.roles.has(role))) {
        return { name: RouteName.PermissionDenied }
      }
    } catch (error) {
      console.error('[Router] Error:', error)
      return { name: RouteName.NotFound }
    }
  })
  return router
}
