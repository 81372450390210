import {
  useQuery,
  type QueryFunction,
  type QueryObserverOptions,
} from '@tanstack/vue-query'
import { getActiveSpan } from '@sentry/browser'

export function getTransaction(): string | undefined {
  const metaElement = import.meta.env.SSR
    ? null
    : document.querySelector<HTMLMetaElement>('meta[name=sentry-trace]')
  if (!metaElement) {
    const sentryTraceHeader = getActiveSpan()?.spanContext().traceId

    return sentryTraceHeader
  }
  return metaElement.content
}

export type GeoIP = {
  country: string
  stateprov: string
  stateprovCode: string
  latitude: string
  longitude: string
  city: string
  continent: string
  timezone: string
  accuracyRadius: number
  asn: number
  asnOrganization: string
  asnNetwork: string
}

export type API = {
  'api/geoip': {
    body: GeoIP
  }
}

type ApiQueryKey = readonly [keyof API, RequestInit]
type ApiQueryFunction = QueryFunction<API[keyof API]['body'], ApiQueryKey>

export const createQueryFunction = (defaultHeaders: RequestInit['headers']) => {
  const queryFunction: ApiQueryFunction = async ({
    queryKey: [path, options],
    signal,
  }) =>
    await fetch(`https://mertico.dev/${path}`, {
      signal,
      ...options,
      headers: {
        'X-Requested-With': import.meta.env.SSR ? 'ssr' : 'client',
        'sentry-trace': getTransaction() || '',
        ...defaultHeaders,
        ...options?.headers,
      },
    }).then((res) => {
      if (res.headers.get('Content-Type')?.includes('application/json')) {
        return res.json()
      }
      return res.text()
    })
  return queryFunction
}

export const useApiQuery = (
  path: keyof API,
  options: RequestInit = {},
  trails: Omit<QueryObserverOptions<API[keyof API]['body']>, 'queryKey'> = {}
) => {
  return useQuery<API[keyof API]['body']>({
    queryKey: [path, options],
    ...trails,
  })
}
