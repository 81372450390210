import type { Router } from 'vue-router'
import { init as SentryInit, browserTracingIntegration } from '@sentry/vue'
// eslint-disable-next-line import/no-unresolved
import type { Vue } from '@sentry/vue/types/types'

export const initSentry = (app: Vue, router: Router) =>
  SentryInit({
    app,
    enabled: Boolean(
      import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_RELEASE_ID
    ),
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      browserTracingIntegration({
        router,
      }),
    ],
    trackComponents: true,
    attachProps: true,
    logErrors: true,
    timeout: 2000,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update', 'unmount'],
    release: import.meta.env.VITE_RELEASE_ID,
    environment: 'production',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1,
    tunnel: '/tunnel/sentry',
    initialScope: (scope) => {
      if (window.__SESSION_ID__) {
        scope.setTag('transaction_id', window.__SESSION_ID__)
      }
      return scope
    },
  })
